import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { PencilSquareIcon, EyeIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { global_epp, global_epp_normal } from "../../helper/currencyHelper";

export const user_columns = () => [
    {
        Header: "id",
        accessor: "u_user_id",
    },
    {
        Header: "name",
        accessor: "user_name",
        Cell: (cell) => {
            const username = cell.row.original
            return <span>{`${username?.u_user_first_name} ${username?.u_user_last_name}`}</span>
        }
    },
    {
        Header: "email",
        accessor: "u_user_email",
    },

    {
        Header: "created at",
        accessor: "u_user_created_at",
        Cell: (cell) => {
            const createdAt = cell.row.original.u_user_created_at
            return <span>{moment(createdAt).format("YYYY-MM-DD")}</span>
        }
    },
    {
        Header: "status",
        accessor: "status",
        Cell: (cell) => {
            const userActive = cell.row.original.u_is_user_active
            return (
                <>
                    <div className="flex gap-5">
                        <Link className="">
                            <span className={`${userActive === "Y" ? "bg-green-100 text-green-800  " : "bg-red-100 text-red-800 "}  text-xs font-medium me-2 px-2.5 py-1 rounded `}>
                                {userActive === "Y" ? "Active" : "Inactive"}
                            </span>
                        </Link>
                    </div>
                </>
            )
        }
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const userID = cell.row.original.u_user_id;
            //console.log("userID", cell.row.original);
            const userActive = cell.row.original.u_is_user_active
            return (
                <>
                    <div className="flex gap-5 relative group ">
                        <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg relative"
                            to={`/edituser/${userID}`}
                        >
                            <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />

                        </Link>
                        <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                            edit user
                        </div>


                    </div>
                </>
            )
        }

    },
]
export const order_columns = () => [
    {
        Header: "Order id",
        accessor: "m_order_id",
    },
    {
        Header: "Name",
        accessor: "m_billing_name",
    },

    {
        Header: "Email",
        accessor: "register_user_email",
        Cell: (cell) => {
            const register_user_email = cell.row.original.register_user_email
            const m_shipping_email = cell.row.original.m_shipping_email
            return (
                <>
                    <span>{register_user_email === null ? m_shipping_email : register_user_email}</span>
                </>
            )
        }
    },
    {
        Header: "Order status",
        accessor: "m_order_status",
        Cell: (cell) => {
            const orderStatus = cell?.row?.original?.m_order_status

            const finalOrder = orderStatus === "Initiated" ? "Pending Payment" : orderStatus.includes("Placed") || orderStatus.includes("Purchese") ? "Processing" : orderStatus
            //console.log("m_order_status",cell.row.original?.m_order_status)
            return (
                <>
                    <span>{finalOrder}</span>
                </>
            )
        }
    },
    {
        Header: "Order Mode",
        accessor: "m_order_mode",
        Cell: (cell) => {
            const orderMode = cell.row.original.m_order_mode

            //console.log("m_order_status",cell.row.original)
            return (
                <>
                    <span>{orderMode}</span>
                </>
            )
        }
    },
    {
        Header: "Amount",
        accessor: "m_total_order_price",
        Cell: (cell) => {
            const currency = cell?.row?.original?.currency;
            const orderid = cell.row.original.m_order_id
            const productPrice = global_epp_normal(parseFloat(cell?.row?.original?.m_total_order_price || 0), currency);
            const shippingPrice = global_epp_normal(parseFloat(cell?.row?.original?.m_shipping_price || 0), currency);
            const couponAmount = global_epp_normal(parseFloat(cell?.row?.original?.m_coupon_amount || 0), currency);
            const customizeAmount = global_epp_normal(parseFloat(cell?.row?.original?.customization_amount || 0), currency);
            const customDiscount = global_epp_normal(parseFloat(cell?.row?.original?.custom_discount_amount || 0), currency);
            const walletAmount = global_epp_normal(parseFloat(cell?.row?.original?.m_wallet_points_applied || 0), currency);



            const totalAmount = ((parseFloat(productPrice) - parseFloat(customDiscount) - parseFloat(couponAmount)) + parseFloat(shippingPrice) + parseFloat(customizeAmount)) - parseFloat(walletAmount);
            // console.log("m_total_order_price",
            //     orderid,
            //     currency,
            //     parseFloat(productPrice),
            //     parseFloat(shippingPrice),
            //     parseFloat(couponAmount),
            //     parseFloat(customizeAmount),
            //     parseFloat(customDiscount),
            //     totalAmount
            // )

            return (
                <>
                    <span>
                        {/* {currency?.split(" ")[1] + " " + parseFloat(totalAmount).toFixed(2)} */}
                        {currency && currency?.split(" ")?.length > 1
                            ? currency?.split(" ")[1] + " " + parseFloat(totalAmount).toFixed(2)
                            : parseFloat(totalAmount).toFixed(2) // Fallback if currency is null or doesn't split
                        }
                    </span>
                </>
            )
        }
    },

    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const order_id = cell.row.original.m_order_id;
            //console.log("order_id", cell.row.original)


            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">

                            <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg"
                                to={`/editorder/${order_id}`}
                            >
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                edit order
                            </div>
                        </div>

                    </div>
                </>
            )
        }
    },

]

export const summary_columns = ({ selectCurrency }) => [
    {
        Header: "productname",
        accessor: (c) => {
            //console.log("summary_columns",c)
            return <span>{c.p_product_name}</span>
        }
    },
    {
        Header: "p_product_sku",
        accessor: "p_product_sku",
    },
    {
        Header: "size",
        accessor: "p_vari_name",
    },
    {
        Header: "p_product_price",
        accessor: "p_product_price",
        Cell: (cell) => {
            const productPrice = cell.row.original.p_product_price;
            const p_vari_id = cell?.row?.original?.p_vari_id;
            const varProductPrice = cell.row.original.reg_price;
            console.log("varProductPrice", varProductPrice)
            const final = p_vari_id ? varProductPrice : productPrice
            return (
                <>
                    <span className="text-sm text-gray-500">

                        {global_epp(final, selectCurrency?.label)}
                    </span>
                </>
            )
        }
    },
    {
        Header: "p_stok_quantity",
        accessor: "p_stok_quantity",
        Cell: (cell) => {
            const p_vari_id = cell?.row?.original?.p_vari_id;
            const productQuantity = cell?.row?.original?.p_stok_quantity;
            const varProductQuantity = parseInt(cell?.row?.original?.p_vari_count);
            const final = p_vari_id ? "1" : "1"
            return (
                <span className="text-sm text-gray-500">{final}</span>
            )
        }
    },
    {
        Header: "total",
        accessor: "total",
        Cell: (cell) => {
            const productPrice = cell?.row?.original?.p_product_price;
            const varProductPrice = cell.row.original.reg_price;

            const productQuantity = 1
            const varProductQuantity = 1
            const p_vari_id = cell?.row?.original?.p_vari_id;
            let totalFinal = 0;
            console.log("totalFinal", cell.row.original)
            if (p_vari_id) {
                if (varProductQuantity === 0 || !varProductQuantity) {
                    totalFinal = varProductPrice
                } else {
                    totalFinal = varProductPrice * varProductQuantity
                }
            } else {
                if (productQuantity === 0 || !productQuantity) {
                    totalFinal = productPrice
                } else {
                    totalFinal = productPrice * productQuantity
                }
            }
            //const total = productQuantity === 0 || !productQuantity ? productPrice : (productPrice * productQuantity)
            return (
                <>
                    <span className="text-sm text-gray-500">{global_epp(totalFinal, selectCurrency?.label)}</span>
                </>
            )
        }
    },
]

export const product_columns = () => [
    {
        Header: "ID",
        accessor: "p_porduct_id",
    },
    {
        Header: "Name",
        accessor: "p_product_name",
    },
    {
        Header: "short description",
        accessor: "p_product_short_desc",
    },
    {
        Header: "sku",
        accessor: "p_product_sku",
    },
    {
        Header: "price",
        accessor: "p_product_price",
    },
    {
        Header: "status",
        accessor: "p_porducts_status",
        Cell: (cell) => {
            const activeStatus = cell?.row?.original?.p_porducts_status;
            const statusClass = activeStatus?.toLowerCase() === "publish"
                ? "bg-green-100 text-green-800"
                : activeStatus?.toLowerCase() === "draft"
                    ? "bg-gray-100 text-gray-800"
                    : activeStatus?.toLowerCase() === "schedule"
                        ? "bg-yellow-100 text-yellow-800" : ""

            return (
                <>
                    {/* <span className={`${activeStatus.toLowerCase() === "publish" ? "bg-green-100 text-green-800 " : activeStatus.toLowerCase() === "draft" ? "bg-gray-100 text-gray-800 " : "bg-yellow-100 text-yellow-800"}  text-xs font-medium me-2 px-2.5 py-1 rounded `}>{activeStatus}</span> */}
                    <span className={`${statusClass} text-xs font-medium me-2 px-2.5 py-1 rounded`}>
                        {activeStatus || ""} {/* Fallback text if activeStatus is null/undefined */}
                    </span>
                </>
            )
        }
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const product_id = cell.row.original.p_porduct_id;
            //console.log("product_id", product_id)
            // console.log("product_id",cell.row.original)
            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg"
                                to={`/editproduct/${product_id}`}
                            >
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                edit product
                            </div>
                        </div>
                        <div className="flex gap-5 relative group">
                            <Link
                                className="px-3 py-1 bg-blue-200/50  rounded-lg"
                                to={`/viewproduct/${product_id}`}
                            >
                                <EyeIcon className="w-5 h-5 text-blue-800 rounded-lg " />

                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                view
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    },
]

export const category_columns = ({ subCatDataHandler }) => [
    {
        Header: "id",
        accessor: "id",
        Cell: (cell) => {
            const catID = cell?.row?.original?.p_product_category_id;
            const subCatID = cell?.row?.original?.p_product_sub_category_id;
            const subCatArray = cell?.row?.original?.subCategory;
            console.log("subCatArray", cell.row.original)
            return (
                <>
                    <span className="">
                        {subCatArray?.length || catID > 0 ? (catID ? catID : '') : (subCatID ? subCatID : "")}
                    </span>
                </>
            )
        }
    },
    {
        Header: "name",
        // accessor: "p_category_name",
        accessor: (row) => {
            const catName = row.p_category_name || "";
            const subCatName = row.p_sub_category_name || "";
            return `${catName} ${subCatName}`.trim(); // Combine both names
        },
        Cell: (cell) => {

            const catID = cell?.row?.original?.p_product_category_id;
            const subCatArray = cell?.row?.original?.subCategory;

            const catName = cell.row.original.p_category_name;
            const subCatName = cell.row.original.p_sub_category_name;


            return (
                <>
                    <span>{subCatArray?.length > 0 || catID ? catName : subCatName}</span>
                </>
            )
        },


    },
    {
        Header: "Type",
        accessor: "type",
        Cell: (cell) => {

            const subCatID = cell.row.original.p_product_sub_category_id;
            return (
                <>
                    <span className={`${subCatID ? "bg-yellow-100 text-yellow-800 " : "bg-purple-100 text-purple-800 "}  text-xs font-medium me-2 px-2.5 py-1 rounded `}>{subCatID ? "subCategory" : "category"}</span>
                </>
            )

        }

    },
    {
        Header: "status",
        accessor: "status",
        Cell: (cell) => {
            //console.log("parent_attribute_columns", cell.row.original)
            const catActive = cell.row.original.p_category_active
            const subCatActive = cell.row.original.p_sub_category_active


            return (
                <>
                    <span className={`${subCatActive === "Y" || catActive === "Y" ? "bg-green-100 text-green-800 " : "bg-red-100 text-red-800 "}  text-xs font-medium me-2 px-2.5 py-1 rounded `}>
                        {subCatActive === "Y" || catActive === "Y" ? "Active" : "Inactive"}
                    </span>
                </>
            )

        }

    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const category_id = cell.row.original.p_product_category_id
            const subCatData = cell?.row?.original?.subCategory;
            const subCatID = cell.row.original.p_product_sub_category_id;

            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg"
                                to={`${subCatData?.length > 0 || category_id ? `/editcategory/${category_id}` : `/editSubCategory/${subCatID}`}`}
                            >
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                {`${subCatData?.length > 0 || category_id ? "edit category" : "edit subcategory"}`}
                            </div>
                        </div>
                        {
                            subCatData?.length > 0 || category_id ?
                                <div className="flex gap-5 relative group">
                                    <button className="px-3 py-1 bg-blue-200/50  rounded-lg"
                                        onClick={() => {
                                            subCatDataHandler(subCatData);
                                            // setIsOpen((prev)=>!prev)
                                        }}
                                    >
                                        <EyeIcon className="w-5 h-5 text-blue-800 rounded-lg " />
                                    </button>
                                    <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                        view
                                    </div>
                                </div>
                                : <></>
                        }

                    </div>
                </>
            )
        }
    },
]

export const subCategory_columns = () => [
    {
        Header: "id",
        accessor: "p_product_sub_category_id",
    },
    {
        Header: "name",
        accessor: "p_sub_category_name",
    },
    {
        Header: "status",
        accessor: "p_sub_category_active",
        Cell: (cell) => {
            //console.log("parent_attribute_columns", cell.row.original)
            const activeStatus = cell.row.original.p_sub_category_active
            return (
                <>
                    <span className={`${activeStatus === "Y" ? "bg-green-100 text-green-800 " : "bg-red-100 text-red-800 "}  text-xs font-medium me-2 px-2.5 py-1 rounded `}>{activeStatus === "Y" ? "Active" : "Inactive"}</span>
                </>
            )

        }

    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const subCat_id = cell.row.original.p_product_sub_category_id;
            // console.log("brand_id", brand_id)
            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">
                            <Link
                                to={`/editSubCategory/${subCat_id}`}
                                className=" px-3 py-1 bg-[#B4E380]/50  rounded-lg ">
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                edit subcategory
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    },
]

export const brand_columns = ({ subBrandHandler }) => [
    {
        Header: "Id",
        accessor: "m_designers_id",
    },
    {
        Header: "name",
        accessor: "m_name",
        // Cell:(cell)=>{
        //     console.log("brand_columns",cell)
        // }
    },
    {
        Header: "status",
        accessor: "m_designer_active",
        Cell: (cell) => {
            //console.log("parent_attribute_columns", cell.row.original)
            const activeStatus = cell.row.original.m_designer_active
            return (
                <>
                    <span className={`${activeStatus === "Y" ? "bg-green-100 text-green-800 " : "bg-red-100 text-red-800 "}  text-xs font-medium me-2 px-2.5 py-1 rounded `}>{activeStatus === "Y" ? "Active" : "Inactive"}</span>
                </>
            )

        }

    },


    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const brand_id = cell.row.original.m_designers_id;
            const subBrandData = cell?.row?.original.subdesigners
            // console.log("subBrandData", subBrandData)

            return (
                <>
                    <div className="flex gap-5">

                        <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg"
                                to={`/editBrand/${brand_id}`}
                            >
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                edit brand
                            </div>
                        </div>
                        {/* <div className="flex gap-5 relative group">
                            <button className="px-3 py-1 bg-blue-200/50  rounded-lg"
                                onClick={() => {
                                    subBrandHandler(subBrandData);
                                    // setIsOpen((prev)=>!prev)
                                }}
                            >
                                <EyeIcon className="w-5 h-5 text-blue-800 rounded-lg " />
                            </button>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                view
                            </div>
                        </div> */}

                    </div>
                </>
            )
        }
    },
]

export const subBrand_columns = () => [
    {
        Header: "m_designers_id",
        accessor: "m_designers_id",
    },
    {
        Header: "m_name",
        accessor: "m_name",
    },
    {
        Header: "status",
        accessor: "m_sub_designer_active",
        Cell: (cell) => {
            //console.log("parent_attribute_columns", cell.row.original)
            const activeStatus = cell.row.original.m_sub_designer_active
            return (
                <>
                    <span className={`${activeStatus === "Y" ? "bg-green-100 text-green-800 " : "bg-red-100 text-red-800 "}  text-xs font-medium me-2 px-2.5 py-1 rounded `}>{activeStatus === "Y" ? "Active" : "Inactive"}</span>
                </>
            )

        }

    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const subBrand_id = cell.row.original.m_sub_designers_id;
            //.log("subBrand_id", subBrand_id)
            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">
                            <Link
                                to={`/editSubBrand/${subBrand_id}`}
                                className=" px-3 py-1 bg-[#B4E380]/50  rounded-lg">
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                editSubBrand
                            </div>
                        </div>

                    </div>
                </>
            )
        }
    },
]

export const tag_columns = () => [
    {
        Header: "name",
        accessor: "name",
    },
    {
        Header: "description",
        accessor: "description",
    },
    {
        Header: "slug",
        accessor: "slug",
    },
    {
        Header: "count",
        accessor: "count",
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            return (
                <>
                    <div className="flex items-center gap-5">

                        <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg">
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                edit tag
                            </div>
                        </div>
                        <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-blue-200/50  rounded-lg"
                            >
                                <EyeIcon className="w-5 h-5 text-blue-800 rounded-lg " />

                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                view
                            </div>
                        </div>

                    </div>
                </>
            )
        }
    },
]

export const page_meta_columns = () => [
    {
        Header: "Page",
        accessor: "name",
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            return (
                <>
                    <div className="flex gap-5">
                        <Link className="text-sm text-blue-500">
                            View
                        </Link>
                        <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg">
                            <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                        </Link>
                    </div>
                </>
            )
        }
    },
]


export const parent_attribute_columns = () => [
    {
        Header: "name",
        accessor: "p_product_attribute_name",
        Cell: (cell) => {
            //console.log("parent_attribute_columns", cell.row.original)
            return (
                <span>{cell.row.original.p_product_attribute_name}</span>
            )

        }

    },
    {
        Header: "status",
        accessor: "p_product_attribute_active",
        Cell: (cell) => {
            //console.log("parent_attribute_columns", cell.row.original)
            const activeStatus = cell.row.original.p_product_attribute_active
            return (
                <>
                    <span className={`${activeStatus === "Y" ? "bg-green-100 text-green-800 " : "bg-red-100 text-red-800 "}  text-xs font-medium me-2 px-2.5 py-1 rounded `}>{activeStatus === "Y" ? "Active" : "Inactive"}</span>
                </>
            )

        }

    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const parent_id = cell.row.original.p_product_attribute_id;

            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg"
                                to={`/edit-parentAttribute/${parent_id}`}
                            >
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                edit parentAttribute
                            </div>
                        </div>


                        {/* <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-blue-200/50  rounded-lg"

                            >
                                <EyeIcon className="w-5 h-5 text-blue-800 rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                view
                            </div>
                        </div> */}

                    </div>
                </>
            )
        }

    },


]

export const child_attribute_columns = () => [
    {
        Header: 'Parent Name',
        accessor: "p_product_attribute_name"
    },
    {
        Header: "name",
        accessor: "p_child_attribute_name",
        Cell: (cell) => {
            //console.log("child_attribute_columns", cell.row.original)
            return (
                <span className="text-sm">{cell.row.original.p_child_attribute_name}</span>
            )
        }

    },
    {
        Header: "status",
        accessor: "p_child_attribute_active",
        Cell: (cell) => {
            // console.log("parent_attribute_columns", cell.row.original)
            const activeStatus = cell.row.original.p_child_attribute_active
            return (
                <span className={`${activeStatus === "Y" ? "bg-green-100 text-green-800 " : "bg-red-100 text-red-800 "} text-xs font-medium me-2 px-2.5 py-1 rounded`}>{activeStatus === "Y" ? "Active" : "Inactive"}</span>
            )

        }

    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const child_id = cell.row.original.p_child_attribute_id;
            //console.log("p_child_attribute_id", child_id)
            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-[#B4E380]/50  rounded-lg"
                                to={`/edit-childAttribute/${child_id}`}
                            >
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                edit childAttribute
                            </div>
                        </div>

                        {/* <div className="flex gap-5 relative group">
                            <Link className="px-3 py-1 bg-blue-200/50  rounded-lg">
                                <EyeIcon className="w-5 h-5 text-blue-800 rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                view
                            </div>
                        </div> */}

                    </div>
                </>
            )
        }

    },
]

export const attributevalues_columns = () => [
    {
        Header: 'Attribute_Name',
        accessor: "p_product_attribute_name"
    },
    {
        Header: 'Name',
        accessor: "p_attribute_value_name"
    },
    {
        Header: 'status',
        accessor: "p_attribute_value_active",
        Cell: (cell) => {
            //console.log("parent_attribute_columns", cell.row.original)
            const activeStatus = cell.row.original.p_attribute_value_active
            return (
                <>
                    <span className={`${activeStatus === "Y" ? "bg-green-100 text-green-800 " : "bg-red-100 text-red-800 "}  text-xs font-medium me-2 px-2.5 py-1 rounded `}>{activeStatus === "Y" ? "Active" : "Inactive"}</span>
                </>
            )

        }
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {

            const attributeValue_id = cell.row.original.p_attribute_value_id;

            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">
                            <Link

                                to={`/edit-values/${attributeValue_id}`}
                                className=" px-3 py-1 bg-[#B4E380]/50  rounded-lg">
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                editValues
                            </div>
                        </div>

                    </div>
                </>
            )
        }
    },
]


export const abandoned_columns = ({ sendEmailHandler }) => [
    {
        Header: 'customer',
        accessor: "customer",
        Cell: (cell) => {
            const firstName = cell?.row?.original?.u_user_first_name;
            const lastName = cell?.row?.original?.u_user_last_name;
            const fullName = `${firstName ? firstName : ""} ${lastName ? lastName : ""}`
            const ipAddress = cell?.row?.original?.user_ip
            return (
                <>
                    <span>{ipAddress ? ipAddress : fullName}</span>
                </>
            )
        }
    },
    {
        Header: 'email',
        accessor: "u_user_email",
        Cell: (cell) => {
            const email = cell?.row?.original?.u_user_email;
            const ipAddress = cell?.row?.original?.user_ip
            return (
                <>
                    <span>{ipAddress ? "" : email}</span>
                </>
            )
        }
    },

    {
        Header: 'sku',
        accessor: "p_product_sku"
    },
    {
        Header: 'cartValue',
        accessor: "p_product_price"
    },
    {
        Header: 'Date',
        accessor: "first_sent_at",
        Cell: (cell) => {
            const createdDate = cell.row.original.first_sent_at
            return <span>{moment(createdDate).format("YYYY-MM-DD")}</span>

        }

    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {

            //const voucher_id = cell.row.original.m_voucher_id;
            const user_id = cell.row.original.u_user_id;
            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">

                            <button type="button" onClick={() => {
                                if (user_id) {
                                    sendEmailHandler(user_id)
                                }

                            }} className="px-3 py-1 bg-[#B4E380]/50  rounded-lg relative"

                            >
                                <EnvelopeIcon className="w-5 h-5 text-[#597445] rounded-lg " />

                            </button>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                sent email
                            </div>
                        </div>

                    </div>
                </>
            )
        }
    },
]

export const vocuher_columns = () => [
    {
        Header: 'Vocuher id',
        accessor: "m_voucher_id"
    },
    {
        Header: 'Vocuher code',
        accessor: "m_voucher_code"
    },
    {
        Header: 'discount value',
        accessor: "m_discounted_value"
    },
    {
        Header: 'applicabel day',
        accessor: "m_applicable_day"
    },
    {
        Header: 'Discount Percentage',
        accessor: "is_flat"
    },
    {
        Header: 'expiry date',
        accessor: "m_expiry_date",
        Cell: (cell) => {
            const expiryDate = cell.row.original.m_expiry_date
            return <span>{expiryDate.slice(0, 19)}</span>
        }
    },
    {
        Header: "status",
        accessor: "is_active",
        Cell: (cell) => {
            //console.log("parent_attribute_columns", cell.row.original)
            const activeStatus = cell.row.original.is_active
            return (
                <>
                    <span className={`${activeStatus === "Y" ? "bg-green-100 text-green-800 " : "bg-red-100 text-red-800 "}  text-xs font-medium me-2 px-2.5 py-1 rounded `}>{activeStatus === "Y" ? "Active" : "Inactive"}</span>
                </>
            )

        }

    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {

            const voucher_id = cell.row.original.m_voucher_id;

            return (
                <>
                    <div className="flex gap-5">
                        <div className="flex gap-5 relative group">
                            <Link

                                to={`/editVoucher/${voucher_id}`}
                                className=" px-3 py-1 bg-[#B4E380]/50  rounded-lg">
                                <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
                            </Link>
                            <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
                                editVoucher
                            </div>
                        </div>

                    </div>
                </>
            )
        }
    },
]

export const wallet_columns = () => [
    {
        Header: 'ID',
        accessor: "m_wallet_logs_id"
    },
    {
        Header: 'Event Name',
        accessor: "u_comment"
    },
    {
        Header: 'Email',
        accessor: "u_user_email"
    },
    {
        Header: 'Avail Balance',
        accessor: "u_avilable_balance"
    },
    {
        Header: 'Amount',
        accessor: "m_amount_added"
    },
    {
        Header: 'Status',
        accessor: "m_wallet_logs_type",
        Cell: (cell) => {
            const status = cell.row.original.m_wallet_logs_type === "add" ? "credit" : "debit"
            return (
                <>
                    <span>{status}</span>
                </>
            )
        }
    },
    {
        Header: 'Date',
        accessor: "m_wallet_logs_created_at",
        Cell: (cell) => {
            const createdDate = cell.row.original.m_wallet_logs_created_at
            return <span>{moment(createdDate).format("YYYY-MM-DD")}</span>

        }

    },
    // {
    //     Header: "action",
    //     accessor: "action",
    //     Cell: (cell) => {

    //         const wallet_logs_id = cell.row.original.m_wallet_logs_id;

    //         return (
    //             <>
    //                 <div className="flex gap-5">
    //                     <div className="flex gap-5 relative group">
    //                         <Link

    //                             to={`/edit-wallet/${wallet_logs_id}`}
    //                             className=" px-3 py-1 bg-[#B4E380]/50  rounded-lg">
    //                             <PencilSquareIcon className="w-5 h-5 text-[#597445] rounded-lg " />
    //                         </Link>
    //                         <div className="absolute bottom-full mb-1 hidden group-hover:block px-2  py-1 bg-gray-500 opacity-75 text-white text-xs rounded-xl">
    //                             editWallet
    //                         </div>
    //                     </div>

    //                 </div>
    //             </>
    //         )
    //     }
    // },
]